
  import { defineComponent } from 'vue';
  import { mapGetters } from 'vuex';
  import Button from '@/components/Button.vue'
  import Input from '~/components/Input/Input.vue'
  import InputTextarea from '@/components/StudyCreation/InputTextarea.vue'
  import CheckBox from '@/components/StudyCreation/CheckBox.vue'
  import { SuiteRoles } from '@/utils/constants'
  export default defineComponent({
    name : 'UserFormModal',
    components:{
      Button,
      Input,
      InputTextarea,
      CheckBox
    },
    props:{
        editUser:{
            type: Object
        },
        isEditForm:{
            type: Boolean,
            default: false
        }
    },
    computed:{
      ...mapGetters([
        'clientStudies'
      ]),
      formEnabled():any{
        const user: any = this.user
        const passwordRegexp = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        const passwordValidation = passwordRegexp.test(user.password) || passwordRegexp.test(user.passwordUpdated) || (this.isEditForm && (this.user.password == null || this.user.password == ""))
        return user.name.length > 2 && user.surname.length > 2 && user.email.length > 5 && passwordValidation
      }
    },
    data(){
      return{
        user:{
            name:'',
            surname:'',
            email:'',
            password: null,
            passwordUpdated: null,
            role:SuiteRoles.ADMIN,
            allContracts: true,
            contractIds: []
        },
        roles:SuiteRoles,
        created: false,
       }
    },
    methods:{
      selectStudy(study: any){
        if(this.user.allContracts) return
        const contractIds: any = this.user.contractIds
        if(contractIds.includes(study.contractId)) contractIds.splice(contractIds.indexOf(study.contractId),1)
        else contractIds.push(study.contractId)
        this.user.contractIds = contractIds
      },
      close(){
        const vm = this
        this.created = false
        setTimeout(function(){
          vm.$emit('close')
        }, 200)
      },
      add(){
        const user: any = this.user
        if(this.user.allContracts) this.user.contractIds = []
        this.$emit('save-user', user)
        this.close()
      }
    },
    mounted(){
      if(this.isEditForm){
        const editUser: any = this.editUser 
        this.user = {
            name: editUser.name,
            surname: editUser.surname,
            email: editUser.email,
            allContracts: editUser.role.allContracts,
            role: editUser.role.role,
            passwordUpdated: null,
            password: null,
            contractIds: editUser.role.allContracts ? [] : editUser.role.contractIds
        }
      }
      this.created = true
    }
  })
  