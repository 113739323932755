
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2'
import StatusCircle from './StatusCircle.vue'
import ProgressBar from './ProgressBar.vue'
import StudyElement from './StudyElement.vue'
import StudyElementFocus from './StudyElementFocus.vue'
import Spinner from './Spinner.vue'
import Button from './Button.vue'
import { ProductTypes } from '@/utils/constants';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
  name : 'StudyCard',
  components:{
    StatusCircle,
    ProgressBar,
    StudyElement,
    StudyElementFocus,
    Spinner,
    Button
  }, setup() {
    const router = useRouter()
    const route = useRoute()

    return {
      router,
      route
    }
  },

  props:{
    study:{
      type: Object,
      default:{contractId:Number},
    }
  },
  computed:{
    ...mapGetters([
      'clientData',
      'isSuperAdmin',
      'isAdmin',
      'isObserver'
    ]),
    hasWatFocus(){
      let hasFocus = false
      const extendedStudy = this.study.extendedStudy
      if(extendedStudy == undefined) return hasFocus
      for(let el of extendedStudy.elements) {
        if(el.productType == ProductTypes.WAT_FOCUS_INDEPENDENT) hasFocus = true
      }
      return hasFocus
    }
  },
  data(){
    return {
      showStudyContent: false,
      booleanStudyElement:false
    }
  },
  methods:{
    handleEvent(booleanStudyElement: boolean) {
      this.$emit("excel-event-two", booleanStudyElement);
    },
    getProductType(element: any){
      return element?.productType
    },
    getParticipantsNum(element: any){
      if(element?.testersFinished) return element.testersFinished
      else if(element?.communityParticipants) return element.communityParticipants
    },
    elementHasProgressBar(element: any){
      if(element?.testersFinished) return true
      return false
    },
    async openCard(){
      if(!this.showStudyContent && this.study.extendedStudy == undefined) await this.$emit('open-card',this.study.contractId)
      this.showStudyContent = !this.showStudyContent
    },
    deleteStudy(){
      let vm = this
      Swal.fire({
        title: vm.$t('suite_swal_confirm'),
        text: vm.$t('suite_swal_study_remove_confirm'),
        showCancelButton: true,
        icon:'warning',
        confirmButtonText: vm.$t('suite_swal_user_remove_action'),
        cancelButtonText: vm.$t('suite_action_cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          vm.$emit('delete-study')
        }
      })
    },
    duplicateStudy(){
      let vm = this
      Swal.fire({
        title: vm.$t('suite_swal_confirm'),
        text: vm.$t('suite_swal_study_duplicate_confirm'),
        showCancelButton: true,
        icon:'warning',
        confirmButtonText: vm.$t('suite_swal_user_duplicate_action'),
        cancelButtonText: vm.$t('suite_action_cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          vm.$emit('duplicate-study')
        }
      }) 
    },
    getEditRouteName(){
      if(this.study.types.includes(ProductTypes.WAT_UX)) return 'newstudy.step2WatUxEdit'
      else if(this.study.elements[0].type == 'COMMUNITY') return 'newstudy.step2WatLabEdit'
      else if(this.study.elements[0].type == 'WAT_FOCUS') return 'newstudy.step2WatFocusEdit'
      else return 'newstudy.step2SurveyEdit'
    },
    editStudy(){
      const contractId = this.study.contractId
      const elementId = this.study.elements[0].elementId
      const routeName = this.getEditRouteName()
      this.router.push({name:routeName, params:{id: contractId, testId: elementId}})
    }
  }

})
